<template>
  <div class="gtm-base__message-page">
    <gtm-layout>
      <gtm-header>
        <span> 通知公告 </span>
      </gtm-header>
      <gtm-content>
        <div class="table-box">
          <el-table :data="messageList" isdrag style="width: 100%" height="100%" v-loading="tableLoading">
            <template slot="empty">
              <gtm-table-empty>暂无通知</gtm-table-empty>
            </template>
            <el-table-column type="index" label="序号" width="58"></el-table-column>
            <el-table-column label="消息类型">
              <template>
                <span>系统通知</span>
              </template>
            </el-table-column>
            <el-table-column label="消息标题">
              <template>
                <span>您有一笔订单开函成功！</span>
              </template>
            </el-table-column>
            <el-table-column prop="orderNo" label="订单编号">
              <template slot-scope="scope">
                <gtm-base-tooltip :content="scope.row.orderNo" :max-width="'300'"></gtm-base-tooltip>
              </template>
            </el-table-column>
            <el-table-column prop="guaranteeTime" label="通知时间">
              <template slot-scope="scope">
                <gtm-base-tooltip :content="scope.row.guaranteeTime" :max-width="'300'"></gtm-base-tooltip>
              </template>
            </el-table-column>
            <el-table-column prop="title" label="操作" width="200">
              <template slot-scope="scope">
                <el-button type="text" @click.stop="onGoToDetail(scope.row)">查看详情</el-button>
              </template>
            </el-table-column>

          </el-table>
        </div>
      </gtm-content>
    </gtm-layout>
  </div>
</template>

<script>
export default {
  name: 'MessagePage',
  components: {},
  mixins: [],
  props: {},
  data () {
    return {
      tableLoading: false,
      messageList: []
    }
  },
  computed: {},
  watch: {},
  created () {
  },
  mounted () {
    this.requestMessageList()
  },
  methods: {
    onGoToDetail (row) {
      let flowType = 'APPLY'
      let { href } = this.$router.resolve({
        path: '/platform/guarantee-web/apply',
        query: {
          orderNo: row.orderNo,
          merchantCode: row.merchantCode,
          productCode: row.productType,
          flowType
        }
      })
      window.open(href, '_blank')
    },
    requestMessageList () {
      let body = {
        merchantCode: '',
        multStatus: 'PASSED,SURRENDER,SURRENDERED,UNSURRENDER',
        orderType: '',
        page: 1,
        pageSize: 99999,
        productType: '',
        searchName: ''
      }
      this.$guaranteeApi.ORDER.fetchOrderList({}, body)
        .then(data => {
          if (data.code === 0) {
            let { result } = data.data
            result = result.map(item => {
              return item.data
            })
            this.messageList = result
          }
        })
        .finally(() => {
          // 隐藏loading
          // hideFullScreenLoading()
        })
    }
  }
}
</script>
<style lang="scss">
.gtm-base__message-page {
  width: 100%;
  height: 100%;

  .table-box {
    height: 100%;
    width: 100%;

    .el-table__header {
      height: 50px !important;
    }

    .el-table__row {
      height: 50px !important;
    }

    .el-table th.el-table__column {
      background: #F0F2F6;
      border-bottom: none;
    }

    .el-table__column:nth-last-child(1) {
      .el-table__column-drag {
        display: none;
      }
    }
  }
}
</style>
